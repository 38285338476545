import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store'
import { closeDialog, DialogType, openDialog } from 'store/dialogs.slice'
import {
  IDialog,
  IEventConfigResponse,
  IFriendInfo,
  IGetTasksResponse,
  ISharingHandler,
  ITimer,
  IUserChanceResponse,
  ShareObjectType,
} from 'types'
import {
  eventStatusToInt,
  getCookie,
  getEventStatus,
  requestInterval,
  sendTrackEvent,
  setTimeOffset,
  getSlotId,
  requestTimeout,
} from './utils'
import {
  useAddShareMutation,
  useLazyGetFriendListQuery,
  useLazyGetShareLinkQuery,
  useShareViaChatMutation,
} from '../store/share-api.slice'
import { useRouter } from 'next/router'
import { getAppSignature, getDeviceID, sharingAppHandler, showWebBridgeToast } from 'shared/webBridge'
import i18next from 'i18next'
import produce from 'immer'
import { appApiAxios, IAxiosBaseQueryError } from './axios-settings'
import { useGetEventConfigQuery, useGetEventNumberOfOpenedBoxQuery } from 'store/api.slice'
import { setEventStatus } from 'store/app.slice'
import { updatePersistentDialog } from './persistent'

export const useDialogs = (dialogType: DialogType): IDialog => {
  const dialogData = useSelector((state: RootState) => state.dialogs?.[dialogType]) as Record<string, unknown> & {
    isOpen: boolean
  }
  const dispatch = useAppDispatch()

  const open = (payload?: Record<string, unknown>) => dispatch(openDialog({ dialogType, ...payload }))

  const close = () => dispatch(closeDialog({ dialogType }))

  return { open, close, ...dialogData }
}

export const useGetFriendList = () => {
  const [data, setData] = useState<IFriendInfo[]>([])
  const [totalFriend, setTotalFriend] = useState<number>(0)
  const deviceIDRef = useRef('')
  const [userShareID, setUserShareID] = useState<number>(0)
  const [appSignature, setAppSignature] = useState<string>('')

  const { query, isReady } = useRouter()
  const eventCode = query.cid as string
  const micrositeID = query.site as string
  const objectType = query['object_type'] as ShareObjectType
  const GET_FRIEND_LIST_LIMIT = 10 as number

  const [getFriendList, { data: friendData, error: friendError, isLoading: friendLoading }] =
    useLazyGetFriendListQuery()
  const [getShareLink, { data: shareLinkData, error: shareLinkError }] = useLazyGetShareLinkQuery()
  const [shareViaChat, { data: shareViaChatData, error: shareViaChatError }] = useShareViaChatMutation()
  const [addShare, { data: addShareData, error: addShareError }] = useAddShareMutation()

  useEffect(() => {
    isReady &&
      !deviceIDRef.current &&
      getDeviceID().then((value) => {
        deviceIDRef.current = value
        getFriendList({
          eventCode,
          deviceID: deviceIDRef.current,
          limit: GET_FRIEND_LIST_LIMIT,
          offset: data.length,
        })
      })
  }, [isReady])

  useEffect(() => {
    // Get friendData response and push it to current data
    if (friendData && friendData.data) {
      setTotalFriend(friendData.total)
      const newData = produce(data, (draft) => {
        friendData.data.forEach((friend) => {
          const idxInData = draft.findIndex((ele) => ele.user_id === friend.user_id)
          idxInData === -1 ? draft.push(friend) : (draft[idxInData] = friend)
        })
      })
      setData(newData)
    } else if (friendError) {
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
    }
  }, [friendData, friendError])

  const loadMore = () => {
    getFriendList({
      eventCode,
      deviceID: deviceIDRef.current,
      limit: GET_FRIEND_LIST_LIMIT,
      offset: data.length,
    })
  }
  // These useEffects are for handling handleShareFriend function
  useEffect(() => {
    if (!userShareID) return
    if (shareLinkData) {
      const signatureData = {
        to_user_id: userShareID,
        from_user_id: parseInt(getCookie('SPC_U') || '0'),
        msg_type: 0, // MSG_TYPE_TEXT
        entrypoint: 1020, // ENTRY_POINT_MB
      }
      getAppSignature(signatureData).then((value) => setAppSignature(value))
    } else if (shareLinkError) {
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
    }
  }, [userShareID, shareLinkData, shareLinkError])

  useEffect(() => {
    if (appSignature !== '' && shareLinkData && userShareID) {
      shareViaChat({ shareID: shareLinkData.share_id, micrositeID, toUserID: userShareID, appSignature })
    }
  }, [appSignature])

  useEffect(() => {
    if (!shareLinkData || !userShareID) return
    if (shareViaChatData) {
      const sharedFriendIdx = data.findIndex((friend) => friend.user_id === userShareID)
      if (sharedFriendIdx !== -1) {
        const newData = produce(data, (draft) => {
          draft[sharedFriendIdx].shared = true
        })
        setData(newData)
      }
      addShare({ shareID: shareLinkData.share_id, toUserID: userShareID })
    } else if (shareViaChatError) {
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
    }
  }, [shareViaChatData, shareViaChatError])

  useEffect(() => {
    if (addShareData) {
      updatePersistentDialog({ selectingEvent: eventCode, willRemovePersistentOnOpen: true })
      const { share_chance_incr: shareChanceIncr } = addShareData

      if (shareChanceIncr > 0)
        showWebBridgeToast({
          message: i18next.t(`Bạn đã nhận ${shareChanceIncr} lượt mở`),
          iconType: 'success',
        })
      else showWebBridgeToast({ message: i18next.t('Đã hết lượt chia sẻ'), iconType: 'failure' })
    } else if (addShareError) {
      updatePersistentDialog({ selectingEvent: eventCode, willRemovePersistentOnOpen: true })
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
    }
  }, [addShareData, addShareError])

  const handleShareFriend = async (toUserID: number) => {
    setUserShareID(toUserID)
    if (!shareLinkData?.share_id) {
      getShareLink({ eventCode, snsType: 'chat', objectType, micrositeID })
    }
  }

  return {
    data,
    isLoading: data.length === 0 && friendLoading,
    error: friendError,
    isReachingEnd: data.length >= totalFriend || totalFriend === 0,
    loadMore,
    handleShareFriend,
  }
}

export const useSharingHandler = () => {
  const [getShareLink, { data: shareLinkData, error: shareLinkError, isFetching: shareLinkFetching }] =
    useLazyGetShareLinkQuery()
  const [addShare, { data: addShareData, error: addShareError, isLoading: addShareLoading }] = useAddShareMutation()
  const [sharingParams, setSharingParams] = useState<ISharingHandler>({
    eventCode: '',
    snsType: 'facebookLink',
    objectType: 'event',
    micrositeID: '',
  })
  const { query } = useRouter()
  const eventCode = query.cid as string

  useEffect(() => {
    if (sharingParams.eventCode === '') return
    if (!shareLinkFetching && shareLinkData) {
      const {
        share_id: shareID,
        share_link: shareLink,
        share_content: shareContent,
        share_image: shareImage,
      } = shareLinkData
      sharingAppHandler(sharingParams.snsType, { url: shareLink, content: shareContent, img: shareImage }, () => {
        // onSuccess
        if (sharingParams.objectType === 'event') {
          addShare({ shareID })
        }
      })
    } else if (shareLinkError) {
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra!'), iconType: 'failure' })
    }
  }, [shareLinkData, shareLinkError, sharingParams, shareLinkFetching])

  useEffect(() => {
    if (addShareLoading) return
    if (addShareData) {
      updatePersistentDialog({ selectingEvent: eventCode, willRemovePersistentOnOpen: true })
      const { share_chance_incr: shareChanceIncr } = addShareData

      if (shareChanceIncr > 0) {
        showWebBridgeToast({
          message: i18next.t(`Bạn đã nhận ${shareChanceIncr} lượt mở`),
          iconType: 'success',
        })
      } else showWebBridgeToast({ message: i18next.t('Đã hết lượt chia sẻ'), iconType: 'failure' })
    } else if (addShareError) {
      updatePersistentDialog({ selectingEvent: eventCode, willRemovePersistentOnOpen: true })
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
    }
  }, [addShareData, addShareError])

  const sharingHandler = (sharingHandlerParams: ISharingHandler) => {
    setSharingParams(sharingHandlerParams)
    getShareLink(sharingHandlerParams)
  }

  return {
    sharingHandler,
  }
}

export const useServerTime = () => {
  useEffect(() => {
    //* Get server time and set to offset
    appApiAxios.get(`${process.env.BASE_API_URL}/delta/time/`).then((res) => setTimeOffset(res.data.data?.['unix_ts']))
  }, [])
}

export const useQueryParamCheck = () => {
  const { query, isReady } = useRouter()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isReady) return
    if (!query.cid || !query.site) {
      dispatch(openDialog({ dialogType: DialogType.ErrorPopup, data: { actionDisable: true } }))
    }
  }, [isReady])
}

export const usePollingConfig = () => {
  const { query, isReady } = useRouter()
  const currentVersion = useRef<number>(0)
  const { refetch: refetchEventConfig } = useGetEventConfigQuery(query.cid as string, {
    skip: !query.cid,
  })
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)

  useEffect(() => {
    if (!isReady || !eventStatus) return
    let timer: ITimer
    if (eventStatus === 'ended') timer?.clear()
    else if (!timer) {
      timer = requestInterval(() => {
        appApiAxios
          .get(`/event-version/?event_code=${query.cid}`)
          .then((res) => {
            const latestVersion = res.data.data?.version || 0
            if (latestVersion === 0 || latestVersion !== currentVersion.current) {
              currentVersion.current = latestVersion
              refetchEventConfig()
            }
          })
          .catch(() => {
            // ignore
          })
      }, 10000)
    }
  }, [isReady, eventStatus])
}

export const usePollingEventOpenedBoxes = ({ isDisplayOpenedBoxes }: { isDisplayOpenedBoxes: boolean }) => {
  const { query, isReady } = useRouter()
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)

  const { refetch: refetchEventOpenedBoxes } = useGetEventNumberOfOpenedBoxQuery(
    { eventCode: query.cid as string },
    { skip: !isReady || !query || !query.cid || !isDisplayOpenedBoxes },
  )

  useEffect(() => {
    if (!isReady || !query || !query.cid || !isDisplayOpenedBoxes || eventStatus === 'up-coming') return
    let timer: ITimer
    if (eventStatus === 'ended') timer?.clear()
    else if (!timer) {
      timer = requestInterval(() => {
        refetchEventOpenedBoxes()
      }, 5000)
    }
  }, [isReady, query.cid, eventStatus])
}

export const useEventStatusCalculation = (data?: IEventConfigResponse) => {
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!data) return

    let timer: ITimer

    const timeToOngoing = data.begin_time - Date.now()
    const timeToEnd = data.end_time - Date.now()

    const currentEventStatus = getEventStatus(timeToOngoing, timeToEnd)
    if (currentEventStatus !== eventStatus) dispatch(setEventStatus(currentEventStatus))

    if (timeToOngoing > 0) {
      timer = requestTimeout(() => {
        dispatch(setEventStatus('on-going'))
      }, timeToOngoing)
    } else if (timeToEnd > 0) {
      timer = requestTimeout(() => {
        dispatch(setEventStatus('ended'))
      }, timeToEnd)
    }

    return () => timer?.clear()
  }, [data, eventStatus])
  return { eventStatus }
}

export const useGetConfigErrorHanlder = (error: IAxiosBaseQueryError) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!error) return
    if (error.status === 500 || error.status === 404) dispatch(openDialog({ dialogType: DialogType.ErrorPopup }))
  }, [error])
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useOnScreen = (ref: MutableRefObject<any>, options?) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined') return
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
    }, options)

    if (ref.current) observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref.current])

  return isIntersecting
}

//* https://stackoverflow.com/questions/53179075/with-useeffect-how-can-i-skip-applying-an-effect-upon-the-initial-render
const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      return fn()
    }
    didMountRef.current = true
  }, inputs)
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useTrackingImpression = ({ ref, inAction, outAction }) => {
  const isOnScreen = useOnScreen(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  })
  const isImpressedRef = useRef(false)

  useDidUpdateEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (isOnScreen) {
      timer = setTimeout(() => {
        inAction?.()
        isImpressedRef.current = true
      }, 1000)
    } else {
      outAction?.()
      isImpressedRef.current = false
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isOnScreen])

  return { isImpressed: isImpressedRef.current }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useViewOfGameTracking = (ref: MutableRefObject<any>) => {
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)
  const isIntersecting = useOnScreen(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.7,
  })
  const slotId = getSlotId()

  useEffect(() => {
    if (slotId === 0 || eventStatus === null) return

    if (isIntersecting) {
      const info = {
        operation: 'view',
        data: {
          game_activity_id: `${getSlotId()}`,
          game_slot_id: `${slotId}`,
          status: eventStatusToInt(eventStatus),
        },
      }
      // console.log('track event #1:', info)
      sendTrackEvent(info)
    }
  }, [isIntersecting, slotId, eventStatus])
}

export const useImpressionBoxTracking = () => {
  const boxList = useRef<(0 | 1 | null)[]>([])
  const [needSendTracking, setNeedSendTracking] = useState(false)
  const slotId = getSlotId()
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)

  const onBoxImpression = (boxIdx: number, isOpen: 0 | 1) => {
    boxList.current[boxIdx] = isOpen
    setNeedSendTracking(true)
  }

  const onBoxOutImpression = (boxIdx: number) => {
    boxList.current[boxIdx] = null
    setNeedSendTracking(true)
  }

  useEffect(() => {
    if (slotId === 0 || eventStatus === null) return
    if (needSendTracking) {
      //* Need a little buffer for not duplicated event
      setTimeout(() => {
        const info = {
          operation: 'impression',
          page_section: 'box',
          data: {
            viewed_objects: boxList.current
              .map((isOpen, boxIdx) => {
                if (isOpen === null) return null
                return {
                  game_activity_id: `${getSlotId()}`,
                  game_slot_id: `${slotId}`,
                  status: eventStatusToInt(eventStatus),
                  box_isopen: isOpen,
                  box_position: boxIdx + 1,
                }
              })
              .filter((e) => e !== null),
          },
        }
        // console.log('track event #2:', info)
        sendTrackEvent(info)
        setNeedSendTracking(false)
      }, 100)
    }
  }, [needSendTracking, slotId, eventStatus])

  return { onBoxImpression, onBoxOutImpression }
}

export const useImpressionTaskDialogTracking = ({
  isTaskDialogOpen,
  chancesData,
  tasksData,
}: {
  isTaskDialogOpen: boolean
  chancesData?: IUserChanceResponse
  tasksData?: IGetTasksResponse
}) => {
  const [currentView, setCurrentView] = useState(0)

  useEffect(() => {
    if (chancesData === undefined || tasksData === undefined) return
    const generalInfo = {
      game_activity_id: `${getSlotId()}`,
      game_slot_id: `${getSlotId()}`,
      status: 1,
      remaining_chances: chancesData?.total_balance,
    }
    const gonnaViewTasksInfo = [
      {
        ...generalInfo,
        task_type: 'act_share',
        task_status: !chancesData?.remaining_share_times ? 1 : 0,
        task_add_chances: chancesData.chances_each_sharing,
      },
      ...(tasksData.tasks ?? []).map((task) => ({
        ...generalInfo,
        task_type: task.action_type,
        task_status: task.status === 'AVAILABLE' ? 0 : 1,
        task_add_chances: task.prize_quantity,
      })),
    ].splice(5 * currentView, 5 * (currentView + 1))

    let timer: ReturnType<typeof setTimeout>
    if (isTaskDialogOpen) {
      timer = setTimeout(() => {
        const info = {
          operation: 'impression',
          page_section: 'quest_popup',
          data: {
            viewed_objects: gonnaViewTasksInfo,
          },
        }
        // console.log('track event #7', info)
        sendTrackEvent(info)
      }, 1000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isTaskDialogOpen, chancesData, tasksData, currentView])

  return { setCurrentView }
}

export const useImpressionClaimPrizeDialogTracking = ({
  isDialogOpen,

  isOpenFromMilestone,
  milestoneNo,
}: {
  isDialogOpen: boolean
  isOpenFromMilestone: boolean
  milestoneNo?: number
}) => {
  useEffect(() => {
    if (!milestoneNo || !isOpenFromMilestone) return

    let timer: ReturnType<typeof setTimeout>
    if (isDialogOpen) {
      timer = setTimeout(() => {
        const info = {
          operation: 'impression',
          page_section: 'more_reward_popup',
          target_type: 'more_reward_popup',
          data: {
            game_activity_id: `${getSlotId()}`,
            milestone_no: milestoneNo,
          },
        }
        sendTrackEvent(info)
      }, 1000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isDialogOpen, isOpenFromMilestone])
}

export const useImpressionYomostClaimPrizeDialogTracking = ({
  isDialogOpen,
  chance_earned,
  eventStatus,
}: {
  isDialogOpen: boolean
  chance_earned?: number
  eventStatus?: number
}) => {
  useEffect(() => {
    if (chance_earned === undefined || eventStatus === undefined) return

    let timer: ReturnType<typeof setTimeout>
    if (isDialogOpen) {
      timer = setTimeout(() => {
        const info = {
          operation: 'impression',
          page_section: 'success_popup',
          target_type: 'success_popup',
          data: {
            game_activity_id: `${getSlotId()}`,
            game_slot_id: `${getSlotId()}`,
            status: eventStatus,
            chance_earned: chance_earned,
          },
        }
        sendTrackEvent(info)
      }, 1000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isDialogOpen, eventStatus])
}

export const useImpressionYomostInputCodeDialogTracking = ({
  isDialogOpen,
  eventStatus,
  isEverSubmitted,
  chancesData,
  max_input_field,
}: {
  isDialogOpen: boolean
  eventStatus?: number
  isEverSubmitted?: boolean
  chancesData?: IUserChanceResponse
  max_input_field?: number
}) => {
  useEffect(() => {
    if (
      eventStatus === undefined ||
      chancesData === undefined ||
      isEverSubmitted === undefined ||
      max_input_field === undefined
    )
      return

    let timer: ReturnType<typeof setTimeout>
    if (isDialogOpen) {
      timer = setTimeout(() => {
        const info = {
          operation: 'impression',
          page_section: 'input_code_popup',
          target_type: 'input_code_popup',
          data: {
            game_activity_id: `${getSlotId()}`,
            game_slot_id: `${getSlotId()}`,
            status: eventStatus,
            pop_up_status: isEverSubmitted ? 1 : 0,
            remaining_chances: chancesData?.total_balance ?? -2,
            max_input_field,
            total_input_code: -1,
            total_valid_codes: -1,
            total_not_existing_codes: -1,
            total_used_codes: -1,
            remaining_input_times: -1,
            is_blocked_user: -1,
          },
        }
        sendTrackEvent(info)
      }, 1000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isDialogOpen, eventStatus, isEverSubmitted, chancesData, max_input_field])
}
